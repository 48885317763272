import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

CookieConsent.run({
  categories: {
    necessary: {
      enabled: true,
      readOnly: true
    },
    analytics: {}
  },

  language: {
    default: 'fr',
    translations: {
      fr: {
        consentModal: {
          title: 'Nous utilisons des cookies',
          description: 'Nous utilisons des cookies pour améliorer votre expérience. <button type="button" data-cc="c-settings" class="cc-link">Personnaliser les préférences</button>',
          acceptAllBtn: 'Tout accepter',
          acceptNecessaryBtn: 'Tout refuser',
          showPreferencesBtn: 'Gérer les préférences'
        },
        preferencesModal: {
          title: 'Gérer les préférences de cookies',
          acceptAllBtn: 'Tout accepter',
          acceptNecessaryBtn: 'Tout refuser',
          savePreferencesBtn: 'Accepter la sélection actuelle',
          closeIconLabel: 'Fermer le modal',
          sections: [
            {
              title: 'Quelqu\'un a dit... cookies ?',
              description: 'J\'en veux un !'
            },
            {
              title: 'Cookies strictement nécessaires',
              description: 'Ces cookies sont essentiels pour le bon fonctionnement du site web et ne peuvent pas être désactivés.',
              linkedCategory: 'necessary'
            },
            {
              title: 'Cookies de performance et d\'analyse',
              description: 'Ces cookies recueillent des informations sur la manière dont vous utilisez notre site web. Toutes les données sont anonymisées et ne peuvent pas être utilisées pour vous identifier.',
              linkedCategory: 'analytics'
            },
            {
              title: 'Plus d\'informations',
              description: 'Pour toute question relative à notre politique en matière de cookies et vos choix, veuillez <a href="#contact-page">nous contacter</a>.'
            }
          ]
        }
      }
    }
  }
});
